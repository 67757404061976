import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/blog_4/b4_1.webp");
const image2 = require("../../../assets/images/blog_4/b4_2.webp");
const image3 = require("../../../assets/images/blog_4/b4_3.webp");
const image4 = require("../../../assets/images/blog_4/b4_4.jpg");
const image5 = require("../../../assets/images/blog_4/b4_5.jpg");
const image6 = require("../../../assets/images/blog_4/b4_6.jpg");
const image7 = require("../../../assets/images/blog_4/b4_7.jpg");
const image8 = require("../../../assets/images/blog_4/b4_8.webp");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Automate MDM Operations: Integrate Intune with Workativ Chatbot"
        description="Automate tasks like Password Reset, User Provisioning, and Access Management with Workativ Assistant Chatbot for Intune. Simplify IT requests today!"
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Integrate Microsoft Intune with ServiceNow and other
                            apps using Workativ
                          </h1>
                          <img src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Mobile devices: odds are, you can hardly remember what
                          life was like without them. No matter where you are,
                          you can reach out to anyone you know in a host of
                          different ways. You can get information about anything
                          you want when you want it. Bored? Entertainment is
                          just a few taps away.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Without a way to manage them efficiently, mobile
                          devices can become an IT nightmare, with potential
                          security leaks, data loss, mobile app management,
                          compatibility issues, user experience gaps, and the
                          need to constantly chase after technology updates and
                          changes. The issue is intensified and made riskier by
                          the fact that employees bring their own personal
                          devices to work to accomplish critical tasks. Mobile
                          devices, while essential for user productivity, add to
                          the management burden of all the Things powering your
                          organization. That includes PCs, Macs, and other
                          devices.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Don’t start losing sleep over mobile devices just yet,
                          because Microsoft Intune and Workativ together lets
                          you:
                        </p>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Securely manage iOS, Android, Windows, and macOS
                            devices with a single solution.
                          </li>
                          <li>
                            Discover whether things are compliant with policies
                            for security, find out when operating systems need
                            updating, and get a complete view of other IT asset
                            management variables.{" "}
                          </li>
                          <li>
                            RStreamline deployment, provisioning, policy
                            management, and updates.{" "}
                          </li>
                          <li>
                            Set up asset management workflows and automate your
                            management processes to save time.{" "}
                          </li>
                          <li>
                            Increase the integrity of information about all your
                            devices.
                          </li>
                          <li>
                            Increase security and minimize data loss by spotting
                            any anomalous behaviors.
                          </li>
                        </ul>
                        <p class="font-section-sub-header-small">
                          Why Workativ?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ is a no-code platform for scaling your IT &
                          HR support with AI-based self-service automation.
                          Delivering remote support for employees is more
                          important than ever and Workativ can help automate
                          employee support with intelligent chatbot and process
                          automation, out-of-the-box. Workativ can be added to
                          any of your instant messaging apps like Slack or
                          Microsoft Teams so that employees can self-serve their
                          IT/HR issues, on-the-go, anytime.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If your business is using a Mobile Device Management
                          (MDM) software like Microsoft Intune, it is high-time
                          you integrated it with Workativ to reap the benefits
                          of automation.
                        </p>
                      </div>
                      <div>
                        <h2 class="font-section-sub-header-small">
                          Microsoft Intune tasks you can automate with Workativ
                        </h2>
                        <img loading="lazy" src={image2} className="mb-5"></img>
                        <p class="font-section-normal-text line-height-2">
                          By integrating Microsoft Intune and Workativ
                          Assistant, you streamline your workplace IT support to
                          connect employees, processes, and automations into a
                          transparent workflow. Let’s take a look at some of the
                          automations you get with Workativ Assistant and
                          Microsoft Intune integration.
                        </p>
                        <ExistingBlogCta
                          ContentCta="Seamlessly Integrate Microsoft Intune with ServiceNow."
                          ButtonText="Book a Demo"
                          isColor="white"
                          backgroundImage={cta_2}
                          mobileBackgroundImage={cta_2_mob}
                        />
                        <h5 class="font-section-sub-header-small">
                          1. Retrieve and delete apps and devices
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ’s Microsoft Intune AI process
                          automation, you can easily let IT Admins or help desk
                          agents retrieve the list of devices on Microsoft
                          Intune as well as the apps installed on them. Help
                          desk agents can ask Workativ to display the list of
                          devices/apps that are in use and Workativ displays all
                          that information in a jiff.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          2. Reset passcode
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Don’t keep your employees waiting for their mobile
                          device to get unlocked by your help desk agent. Let
                          them instantly unlock their device using Workativ’s
                          Microsoft Intune process automation that lets them
                          reset their passcode in a matter of minutes.
                        </p>
                        <img loading="lazy" src={image3} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          3. Remote lock and data wipe devices
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          If an employee loses their mobile device, it not only
                          puts their information at risk but also the company’s
                          information as well if they have been using that
                          device under the company’s BYOD program. Get rid of
                          this worry by letting employees bring up Workativ on
                          their Slack or Microsoft Teams channel and lock and
                          wipe their device’s data in the blink of an eye.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          Microsoft Intune and Workativ for comprehensive mobile
                          device management
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ, you can connect multiple apps and set
                          up an automation involving a synergistic workflow of
                          tasks on those apps. Let’s take a look at some of the
                          apps you can combine with Microsoft Intune using
                          Workativ.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1.Assign apps (Microsoft Intune and Office 365)
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          You can effortlessly set up an automation using
                          Workativ and Microsoft Intune such that for employees
                          enrolling their device under the company’s BYOD
                          program or for company-issued mobile devices, Office
                          365 gets installed on their device without them having
                          to do anything. This ensures that they are able to
                          keep up with emails from their teammates on-the-go.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          2. Security
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          By integrating Microsoft Intune with Workativ, you can
                          set up an automation such that Workativ remotely locks
                          a mobile device and wipes all the data on it the
                          moment an employee reports it in as lost or stolen to
                          Workativ.
                        </p>
                        <img loading="lazy" src={image4} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          3. Microsoft Intune integration with ServiceNow
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Integrating Intune with ServiceNow and Workativ has
                          huge implications for incident management. This means
                          that when users submit an asset-related incident on
                          Workativ, service managers can address said incident
                          within Workativ. For example, if a user loses their
                          device, a service manager can immediately lock or wipe
                          that device without leaving Workativ.
                        </p>
                        <img loading="lazy" src={image5} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          How you can set up a Microsoft Intune chatbot using
                          Workativ
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen the endless possibilities of what
                          integrating Workativ and Microsoft Intune and your
                          existing apps can do for you, let’s see how you can go
                          about the same.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img loading="lazy" src={image6} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code chatbot builder helps you
                          build and manage simple to complex conversations,
                          FAQs, integrate app workflows, and personalize your
                          bot in minutes. Deliver autonomous workplace support
                          with purpose built intelligent chatbots.
                        </p>
                        <img loading="lazy" src={image7} className="mb-3"></img>
                        <img loading="lazy" src={image8} className="mb-5"></img>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        Perks of Workativ + Microsoft Intune
                      </h6>

                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. Secure Bring-Your-Own-Device (BYOD) programs for
                          your employees.
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          A lot of employees don’t want to have to carry a
                          second device around, so Bring-Your-Own-Device (BYOD)
                          programs are growing in popularity among
                          organizations. However, getting employees to enroll
                          their personal devices into some sort of management
                          program is a major challenge, because many employees
                          don’t want their employers to have access to their
                          devices.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ + Intune offers a BYOD approach that makes
                          securing BYOD devices faster and efficient. Help desk
                          agents can get Workativ to assign apps or lock or wipe
                          a device in a matter of minutes.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. Limit employee access to corporate data only to
                          devices that you control.
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Have your employees ever used a public kiosk to access
                          their corporate data (for instance, used a public
                          computer at a trade show or in a hotel lobby to sign
                          into their email)?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Intune and Workativ let you easily limit the access to
                          your corporate data to only devices that your
                          organization manages. This is done via Workativ’s
                          conversational interface that’s easy to use and get
                          work done in a matter of minutes.{" "}
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. Creating a Synergy between your existing Apps
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          You can combine your other existing apps with
                          Microsoft Intune creating an automated workflow that
                          does much more with those apps in just a single run.
                        </p>

                        <h5 className="font-section-sub-header-small">
                          4. Microsoft Intune Self Service
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          Workativ provides users with a holistic self-service
                          interface for all of their IT needs. By integrating
                          Intune with Workativ, end users can view information
                          about their assigned devices, use self-service Intune
                          actions, and enroll devices within the same interface
                          they manage the rest of their IT assets. This
                          integration saves time and greatly enhances the user
                          experience.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          Liked what you read about Workativ? Try Workativ today
                          by signing up for a FREE trial at workativ.com and
                          experience the difference yourself.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Microsoft Intune Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="microsoft intune chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
